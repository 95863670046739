import gsap from "gsap";
import ScrollTrigger from "../utils/ScrollTrigger.min";
import Score from "./Score";
import CookieUtil from "../utils/Cookie";

require('hammerjs');
export default class Tinder {
  constructor(scroll, lenis) {
    this.lenis = lenis
    this.init();
    this.initEvent();
  }

  init() {
    this.switchColorMenu();
  }

  switchColorMenu() {
    let tl = gsap.timeline();

    tl.to(".section3", {
      scrollTrigger: {
        trigger: "#futur-metier",
        start: "-6%",
        end: "-4%",
      },
      onUpdate: () => {
      },
      onStart: () => {
        document.getElementById('like').click();
      },
      onComplete: () => {
      },
    })
  }

  initEvent() {

    let lenis = this.lenis
    let score = {
      nature: 0,
      climat: 0,
      numerique: 0,
      ville: 0,
    }

    let popup = document.querySelector(".popup-mention");
    document.querySelector("#mention").addEventListener("click", (e) => {
      e.preventDefault();
      popup.classList.toggle("active")
    })
    document.querySelector(".icon-close").addEventListener("click", (e) => {
      popup.classList.remove("active")
    })
    this.setRandomCards()
    loadTinderGame();

    function loadTinderGame() {
      if ($('.tinder').length > 0) {
        //JS TINDER SWIPE
        'use strict';

        //fix scroll on mobile
        // $('body').css('overflow', 'hidden');
        // $('body').css('position', 'fixed');
        // $('body').css('left', '0');
        // $('body').css('right', '0');


        var allCards = document.querySelectorAll('.tinder-card');
        var nope = $('.nope');
        var love = $('.love');


        initCards();


        allCards.forEach(function (el) {
          var hammertime = new Hammer(el);

          hammertime.on('pan', function (event) {
            disabledScroll();
            el.classList.add('moving');
          });

          hammertime.on('pan', function (event) {
            if (event.deltaX === 0) return;
            if (event.center.x === 0 && event.center.y === 0) return;

            event.target.parentNode.parentNode.classList.toggle('tinder_love', event.deltaX > 50);
            event.target.parentNode.parentNode.classList.toggle('tinder_nope', event.deltaX < -50);

            var xMulti = event.deltaX * 0.03;
            var yMulti = event.deltaY / 80;
            var rotate = xMulti * yMulti;


            event.target.style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px) rotate(' + rotate + 'deg)';
          });

          hammertime.on('panend', function (event) {
            el.classList.remove('moving');

            $(".promptBox").addClass("disabled");
            var moveOutWidth = document.body.clientWidth;
            var keep = Math.abs(event.deltaX) < 80;

            event.target.classList.toggle('removed', !keep);

            if (keep) {
              event.target.style.transform = '';

              event.target.parentNode.parentNode.classList.remove('tinder_love');
              event.target.parentNode.parentNode.classList.remove('tinder_nope');
            } else {
              var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
              var toX = event.deltaX > 0 ? endX : -endX;
              var endY = Math.abs(event.velocityY) * moveOutWidth;
              var toY = event.deltaY > 0 ? endY : -endY;
              var xMulti = event.deltaX * 0.03;
              var yMulti = event.deltaY / 80;
              var rotate = xMulti * yMulti;

              event.target.style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px) rotate(' + rotate + 'deg)';

              let value = "";
              if (event.deltaX > 0) {
                value = "vrai";
              } else {
                value = "faux";
              }
              sendScore(event.target, value);

              setTimeout(() => {
                event.target.parentNode.parentNode.classList.remove('tinder_love');
                event.target.parentNode.parentNode.classList.remove('tinder_nope');
              }, 100)
            }
          });
        });

        var nopeListener = createButtonListener(false);
        var loveListener = createButtonListener(true);

        nope.on('click', nopeListener);
        love.on('click', loveListener);
      }
    }

    function initCards(card, index) {
      var newCards = document.querySelectorAll('.tinder-card:not(.removed)');
      var allCards = document.querySelectorAll('.tinder-card');
      var tinderContainer = $('.tinder');
      $('.tinder-cards').css({'opacity': '1'});

      var cpt = 0;
      newCards.forEach(function (card, index) {
        card.style.zIndex = allCards.length - index;
        card.style.transform = ('translate(' + index * 3 + 'px, ' + index * 3 + 'px)');

        if (index > 5) {
          card.style.opacity = (10 - index * 10) / 10;
        } else {
          card.style.opacity = 1;
        }

        if (!$(card).hasClass('removed')) {
          cpt++;
          if (cpt > 1) {
            card.style.pointerEvents = 'none';
          } else {
            card.style.pointerEvents = 'auto';
          }
        }
      });

      tinderContainer.addClass('loaded');

      if (newCards.length == 0) {

        $('.tinder-cards-header').addClass('transition').removeClass('finish');

        $('.tinder-cards-header').on("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend", function () {

          if ($('#loading').length > 0) {
            $('#loading').addClass('active');
            initAnimation('#loading');
            $('#loading').find('.loading--container').addClass('active-fast');

            $('#loading').find('.loading--container .loading--container--after').on("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function () {
              $('#loading').removeClass('active');
              $('#form-tinder').submit();
            });
          } else {
            $('#form-tinder').submit();
          }

        });
      }
    }

    function createButtonListener(love) {

      return function (event) {
        disabledScroll();
        var cards = document.querySelectorAll('.tinder-card:not(.removed)');
        var moveOutWidth = document.body.clientWidth * 1.5;

        if (!cards.length) return false;

        var card = cards[0];

        card.classList.add('removed');
        let value = "";

        if (love) {
          value = "vrai";
          card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(30deg)';

          card.parentNode.parentNode.classList.toggle('tinder_love');

        } else {
          card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(-30deg)';
          value = "faux";

          card.parentNode.parentNode.classList.toggle('tinder_nope');
        }

        setTimeout(() => {
          card.parentNode.parentNode.classList.remove('tinder_love');
          card.parentNode.parentNode.classList.remove('tinder_nope');
        }, 200);

        sendScore(card, value);

        event.preventDefault();
      };
    }

    var isFix = false;

    function disabledScroll() {
      if (!$("html").hasClass("overflow-tinder") && !isFix) {
        isFix = true;
        $('html, body').animate({
          scrollTop: $("#futur-metier").offset().top
        }, 0, 'swing', function () {
          $("html").addClass("overflow-tinder")
          lenis.stop();
        });
      }
    }

    function sendScore(card, value) {
      //si ils commencent le jeu, ils doivent le finir
      CookieUtil.set('gameLaunched', '#dessine-univers', 7);

      var category = $(card).attr("data-category");
      $(".promptBox").addClass("disabled");

      if ($(card).find('.tinder-card--bonus').length > 0 && value == "vrai") {
        $(card).removeClass('removed');
        $(card).find('.tinder-card--bonus').addClass('active');
        $(card).parent().parent().find('.tinder-buttons').css('pointer-events', 'none');

        setTimeout(function () {
          $(card).find('.tinder-card--bonus').removeClass('active');
          $(card).addClass('removed');
          $(card).parent().parent().find('.tinder-buttons').css('pointer-events', '');
          initCards();
        }, 1500)
      }
      if (value == "vrai") {
        score[category] += 1;
      }

      if ($(card).parent().find('.tinder-card:not(.removed)').length == 0) {
        $(card).parent().parent().find('.tinder-buttons').find('button').addClass('d-none');
        $(card).parent().parent().find('.tinder-buttons').find('.tinder-loading').removeClass('d-none');

        let valueMax = Object.entries(score).sort((prev, next) => prev[1] - next[1])
        let blockTrigger = valueMax.pop();

        document.querySelector(`.section4.${blockTrigger[0]}`).classList.add("active");


        lenis.start();
        document.querySelector("header.header").style.display = "none";

        $("html").removeClass("overflow-tinder");
        gsap.to("body", {
          autoAlpha: 0,
          duration: .44,
          onComplete: () => {
            $("body").addClass("ending");
            document.querySelector("footer").style.display = "block";
            gsap.to("body", {
              autoAlpha: 1,
              duration: .44,
              onStart: () => {
                $('html, body').animate({
                  scrollTop: 0
                }, 0)
                // $("header.header").removeClass("overlap")
              }
            })
          }
        })


        ScrollTrigger.refresh();
        let scoreBlock = new Score(document.querySelector(".section4.active"), lenis, '#futur-metier');
        lenis.start();

      }

      initCards();
    }
  }

  setRandomCards() {

    let climat = $('.tinder-card[data-category="climat"]');
    let nature = $('.tinder-card[data-category="nature"]');
    let numerique = $('.tinder-card[data-category="numerique"]');
    let ville = $('.tinder-card[data-category="ville"]');
    let allCardDeleting = [];
    allCardDeleting.push(this.getRandom(climat, 4))
    allCardDeleting.push(this.getRandom(nature, 4))
    allCardDeleting.push(this.getRandom(numerique, 4))
    allCardDeleting.push(this.getRandom(ville, 4))

    allCardDeleting.forEach((item, index) => {
      let parent = item;
      parent.forEach((item) => {
        $(item).remove();
      })
    })
    $(".tinder-cards").html($(".tinder-cards .tinder-card").sort(function () {
      return Math.random() - 0.5;
    }));

  }

  getRandom(arr, n) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}
