import gsap from "gsap";
import confetti from "canvas-confetti";
import CookieUtil from "../utils/Cookie";

export default class Score {
  constructor(block, lenis, game) {
    this.el = block;

    CookieUtil.set('gameLaunched', game, 7);

    this.init();
    setTimeout(() => {
      document.getElementById('demain').click();
      window.scrollTo(1,1)
      window.scrollTo(0,0)
      window.scrollTo(1,1)
      window.dispatchEvent(new Event('resize'));
      // lenis.scrollTo(0)
      // @TODO grossir confitti
      confetti({
        particleCount: 400,
        spread: window.innerWidth < 768 ? 100 : 200,
        scalar: 1.4,
      });

    }, 777)
  }

  init() {
    this.switchColorMenu();
    // @TODO Init slide quand tinder et fait
    this.initSlide();
    this.initUrlSliderMetier();

  }

  switchColorMenu() {
    let tl = gsap.timeline();

    tl.to("header .inner", {
      scale: 0.4,
      onComplete: () => {
        document.querySelector(".header").classList.add('active');
      },
      onUpdate: () => {
        document.querySelector(".header").classList.remove('active');
      },
      scrollTrigger: {
        trigger: ".section4.active",
        start: "-10%",
        end: "5%",
      }
    }).to(".yellow", {
      autoAlpha: 0,
      scrollTrigger: {
        trigger: ".section4.active",
        start: "-10%",
        end: "5%",
      }
    }).to(".blue", {
      autoAlpha: 1,
      scrollTrigger: {
        trigger: ".section4.active",
        start: "-10%",
        end: "5%",
      }
    })
  }

  initSlide() {
    document.querySelector(".section4.active").style.display = "block";
    document.querySelector(".discover").style.display = "block";
    $("html").removeClass("overflow-tinder")
    let trigger = this.el.querySelector('.slider-metier');
    $(trigger).slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      arrows: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }).on('setPosition', function (event, slick) {
      $(slick.$slideTrack).find(".slick-slide").height('auto').css('height', $(slick.$slideTrack).height() + 'px');
    });
  }

  initUrlSliderMetier() {
    const target = document.querySelector('.section4.active .slider-metier-container');

// Next we want to create a function that will be called when that element is intersected
    function handleIntersection(entries) {
      // The callback will return an array of entries, even if you are only observing a single item
      entries.map((entry) => {
        if (entry.isIntersecting) {
          document.getElementById('metiers').click();
        } else {
        }
      });
    }

// Next we instantiate the observer with the function we created above. This takes an optional configuration
// object that we will use in the other examples.
    const observer = new IntersectionObserver(handleIntersection);

// Finally start observing the target element
    observer.observe(target);
  }
}
