require('intersection-observer');
const initIntersection = (target, threshold, canHover) => {
  var cible = document.querySelectorAll(target);
  var options = {
    root: null,
    rootMargin: "0px",
    threshold: threshold,
  };


  var observer3 = new IntersectionObserver(
    onIntersection,
    options
  );

  cible.forEach((observe) => {
    observer3.observe(observe);
  })

  function onIntersection(entries) {
    var target;
    entries.forEach(function (entry) {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('is-inview');
        observer3.unobserve(entry.target);
      }

    })

  }
}

export { initIntersection };
