import SplitText from "../utils/SplitText.min";
import gsap from "gsap";

export default class Jouer {
    constructor() {
        this.init();
    }

    init() {

        document.querySelectorAll(".animation .title-splite").forEach((item) => {
            let tl = gsap.timeline();
            let split = new SplitText(item, {type: "words,lines"});
            tl.from(split.words, {
                opacity: 0,
                y: 100,
                ease: "power1.inOut",
                stagger: {
                    from: "start",
                    each: 0.05
                },
                scrollTrigger: {
                    trigger: item.parentNode,
                    start: "-50%",
                    end: "50%",
                }
            }).to(split.words, {
                y: -100,
                opacity: 0,
                ease: "power1.inOut",
                stagger: {
                    from: "start",
                    each: 0.05
                },
                scrollTrigger: {
                    trigger: item.parentNode,
                    start: "30%",
                    end: "bottom",
                }
            });
        })

    }
}
